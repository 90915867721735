export const getPersonList = async () => {
  try {
    const response = await fetch('http://localhost:8000/personList.json');

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

//Face Recognition Requests
const baseUrl = window.API_URL;

//AUTHENTICATE

/* eslint-disable camelcase */
export async function login(username, password) {
  const url = `${baseUrl}/login`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: '',
        username,
        password,
        scope: '',
        client_id: '',
        client_secret: '',
      }),
    });
    if (response.ok) {
      const { access_token } = await response.json();
      return { access_token };
    }

    throw new Error('Login failed');
  } catch (error) {
    throw new Error('Login failed');
  }
}

async function getData(url) {
  const token = localStorage.getItem('access_token');
  console.log('TOKEN', token);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      return response.json();
    }

    if (response.status === 404) {
      return 'Command Not Found.';
    }

    if (response.status === 504) {
      return 'Server Timed Out.';
    }

    if (response.status === 422) {
      return 'Server Could Not Process Request.';
    }

    if (response.status === 401) {
      // Redirect the user to the login page
      window.location.href = '/auth/loginformik';
    }

    return 'An error occurred from the server.';
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
}

//GET REQUESTS

export async function getPerson(personId) {
  const url = `${baseUrl}/person/?id=${personId}`;
  const data = await getData(url);

  console.log('Person Data:', data);

  return data;
}

export async function getPersonAndProfilePicture(personId) {
  const url = `${baseUrl}/person/all/?id=${personId}`;
  const data = await getData(url);

  //console.log('Person Data & Picture:', data);

  return data;
}

export async function getProfilePicture(personId) {
  const url = `${baseUrl}/person/picture/?id=${personId}`;

  try {
    const data = await getData(url);
    console.log('Person Profile Picture:', data);
    return data;
  } catch (error) {
    console.error('Error fetching profile picture:', error);
    return null;
  }
}

export async function getPersonByName(personName, personSurname) {
  const url = `${baseUrl}/person/search/?name=${personName}&surname=${personSurname}`;
  const data = await getData(url);

  console.log('Person By Name Data:', data);

  return data;
}

export async function getTransaction(transactionId) {
  const url = `${baseUrl}/transaction/?id=${transactionId}`;
  const data = await getData(url);

  console.log('Transaction Data:', data);

  return data;
}

export async function getPictureByTransaction(transactionId) {
  const url = `${baseUrl}/transaction/arguments/picture?id=${transactionId}`;
  const data = await getData(url);

  console.log('Transaction Data:', data);

  return data;
}

export async function getTransactionComplete(transactionId) {
  const url = `${baseUrl}/transaction/complete/?id=${transactionId}`;
  const data = await getData(url);

  console.log('Transaction Data:', data);

  return data;
}

export async function getLatestTransactions(numOfTransactions) {
  const url = `${baseUrl}/transaction/latest/?N=${numOfTransactions}`;
  const data = await getData(url);

  console.log('Latest Transactions:', data);

  return data;
}

export async function getTransactionsByCountry(numOfTransactions, domain) {
  const url = `${baseUrl}/transaction/country/?N=${numOfTransactions}&cc=${domain}`;
  const data = await getData(url);

  console.log('Transactions by Country:', data);

  return data;
}

export async function getSearchTransaction(
  startDate = '',
  endDate = '',
  type = '',
  domain = '',
  result = '',
  numOfTransactions = '',
  page = 1,
  order = '',
) {
  let url = `${baseUrl}/transaction/search/?`;

  if (startDate) {
    url += `&start=${startDate}`;
  }
  if (endDate) {
    url += `&end=${endDate}`;
  }
  if (type) {
    url += `&tt=${type}`;
  }
  if (domain) {
    url += `&cc=${domain}`;
  }
  if (result !== undefined && result !== null && result !== '') {
    url += `&result=${result}`;
  }
  if (numOfTransactions) {
    url += `&N=${numOfTransactions}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (order) {
    url += `&order=${order}`;
  }

  try {
    const data = await getData(url);

    console.log('Transactions:', data);

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);

    if (error.status === 401) {
      // Redirect the user to the login page
      window.location.href = '/auth/loginformik';
    }
    // Rethrow the error to ensure it bubbles up
    throw error;
  }
}

export async function getTransactionsByDate(
  startDate,
  endDate,
  type = '',
  domain = '',
  result = '',
  numOfTransactions = '',
) {
  let url = `${baseUrl}/transaction/inbetween/?start=${startDate}&end=${endDate}`;

  // Append optional parameters if provided
  if (type) {
    url += `&tt=${type}`;
  }
  if (domain) {
    url += `&cc=${domain}`;
  }
  if (domain) {
    url += `&result=${result}`;
  }
  if (numOfTransactions) {
    url += `&N=${numOfTransactions}`;
  }

  const data = await getData(url);

  console.log('Transactions:', data);

  return data;
}

export async function getTransactionType(transactionId) {
  const url = `${baseUrl}/transaction/type/?id=${transactionId}`;
  const data = await getData(url);

  console.log('Transaction Type:', data);

  return data;
}

export async function getPersonSummary(personId) {
  const url = `${baseUrl}/person/summary/?id=${personId}`;
  const data = await getData(url);

  console.log('Person Summary:', data);

  return data;
}

//POST REQUESTS

export async function createUser(username, password) {
  const url = `${baseUrl}/user/`;

  const token = localStorage.getItem('access_token');

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('User Created:', data);
      return data;
    }

    if (response.status === 404) {
      console.error('Command Not Found.');
    } else if (response.status === 504) {
      console.error('Server Timed Out.');
    } else if (response.status === 422) {
      console.error('Server Could Not Process Request.');
    } else {
      console.error('An error occurred from the server.');
    }

    // Add a default return statement in case of error
    return { error: 'An error occurred.' };
  } catch (error) {
    console.error('Error:', error.message);
    return { error: 'An error occurred.' };
  }
}

export default {
  getPersonList,
  getPerson,
  getPersonAndProfilePicture,
  getPersonByName,
  getPersonSummary,
  getTransaction,
  getTransactionType,
  getTransactionsByCountry,
  getLatestTransactions,
  getProfilePicture,
  login,
  createUser,
};
