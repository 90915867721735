import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Card, CardBody, Row, Col, Label, Input, Button } from 'reactstrap';
import {
  getTransaction,
  getTransactionComplete,
  getProfilePicture,
} from '../../helpers/DataManager';
//import noImage from '../../assets/images/users/noImage.jpg';
import Loader from '../../layouts/loader/Loader';

const TransactionInfo = () => {
  const [transaction, setTransaction] = useState(null);
  const [completeTransaction, setCompleteTransaction] = useState(null);
  const [indentificationPersonPicture, setIdentificationPersonPicture] = useState(null);
  const [showPersonDetails, setShowPersonDetails] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = location;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (id) {
          const transactionData = await getTransaction(id);
          if (transactionData.Transaction) {
            setTransaction(transactionData.Transaction);

            const personPicture = await getProfilePicture(transactionData.Transaction.persona_id);
            setIdentificationPersonPicture(personPicture.Base64_Picture);

            const transactionCompleteData = await getTransactionComplete(id);
            setCompleteTransaction(transactionCompleteData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (id) {
      const existingArray = sessionStorage.getItem('clickedTransactionIds')
        ? JSON.parse(sessionStorage.getItem('clickedTransactionIds'))
        : [];

      if (!existingArray.includes(id)) {
        const updatedArray = [...existingArray, id];

        // Update sessionStorage
        sessionStorage.setItem('clickedTransactionIds', JSON.stringify(updatedArray));
      }
    }
  }, [id]);

  const handleBackButton = () => {
    navigate('/dashboards/tablefacerecognition', { state });
  };

  console.log(indentificationPersonPicture);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    return date
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(',', '');
  };

  const togglePersonDetails = () => {
    setShowPersonDetails(!showPersonDetails);
  };

  const renderInputField = (label, value, isBox, isList) => (
    <Col
      xl="3"
      lg="6"
      md="5"
      sm="5"
      xs="10"
      className="d-flex flex-column justify-content-center input-details-width ps-5 ms-3 me-5 align-items-center"
    >
      <div className="input-details-width">
        <Label className="text-primary input-details-width mt-2 fw-bold">{label}:</Label>
      </div>
      {isBox ? (
        <div className="input-box bg-white form-control text-primary input-details-width h-100">
          {isList ? (
            <ul>
              {value.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          ) : (
            value
          )}
        </div>
      ) : (
        <Input
          disabled
          value={value || ''}
          type="text"
          className="bg-white form-control text-primary input-details-width"
        />
      )}
    </Col>
  );

  return (
    <div className="pb-5 mb-sm-3 mb-5">
      {loading ? (
        <div className="text-center mt-1">
          <Loader />
        </div>
      ) : transaction && completeTransaction ? (
        <div>
          <Row>
            <Col sm="12" className="mt-2">
              <Button color="accent" style={{ zIndex: '4' }} onClick={handleBackButton}>
                <Icon.ArrowLeft size={16} /> Back
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pt-2 gy-2">
            <Col sm="12" className="">
              <Card className="shadow h-100 p-2 py-4 mt-3 mb-3 bg-white">
                <CardBody>
                  <fieldset className="card-legend border px-3 py-2 h-100 bg-white">
                    <legend className="card-legend text-center text-primary bg-white">
                      Transaction Details
                    </legend>
                    <Row className="justify-content-center">
                      <Col
                        sm="12"
                        md="12"
                        xl="3"
                        className="text-center mb-2 py-3 px-0 image-col-width"
                      >
                        {completeTransaction?.faceDetection.images?.length > 0 && (
                          <div>
                            <span className="highlight text-primary mt-1 mb-2 h4">
                              Face portrait image
                            </span>
                            <img
                              src={`data:image/jpeg;base64,${completeTransaction.faceDetection.images[0]}`}
                              alt="Transaction"
                              className="img-width"
                            />
                          </div>
                        )}
                      </Col>
                      <Col
                        sm="12"
                        xl="1"
                        className="mb-2 d-flex justify-content-center h-icon align-items-center text-primary"
                      >
                        <Icon.Repeat className="icon-size" size={30} />
                      </Col>
                      <Col
                        sm="5"
                        xl="3"
                        className="text-center pt-3 mb-2 px-0 py-3 image-col-width"
                      >
                        {completeTransaction?.faceDetection.images?.length > 0 &&
                        (transaction.type === 'Identification' ||
                          transaction.type === 'Mis-Identification') ? (
                          <div>
                            <span className="highlight text-primary mt-1 mb-2 h4">
                              Matched face portrait image
                            </span>
                            {indentificationPersonPicture ? (
                              <img
                                src={`data:image/jpeg;base64,${indentificationPersonPicture}`}
                                alt="Identification Person"
                                className="img-width"
                              />
                            ) : (
                              <Icon.CameraOff className="img-width mt-4" />
                            )}
                          </div>
                        ) : (
                          completeTransaction?.faceDetection.images?.length > 1 && (
                            <div>
                              <span className="highlight text-primary mt-1 mb-2 h4">
                                Matched face portrait image
                              </span>
                              <img
                                src={`data:image/jpeg;base64,${completeTransaction.faceDetection.images[1]}`}
                                alt="Person"
                                className="img-width"
                              />
                            </div>
                          )
                        )}
                      </Col>

                      <Col sm="12" xl="4" className="mb-2">
                        <Row className="justify-content-center">
                          <Col
                            xl="9"
                            lg="6"
                            md="5"
                            sm="7"
                            xs="10"
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="input-width">
                              <Label className="text-primary mt-2 p-label fw-bold text-start">
                                Matching Score:
                              </Label>
                            </div>
                            <Input
                              disabled
                              value={completeTransaction.location.properties.matchingScore}
                              type="text"
                              className="bg-white form-control text-primary input-width "
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col
                            xl="9"
                            lg="6"
                            md="5"
                            sm="7"
                            xs="10"
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="input-width">
                              <Label className="text-primary mt-2 p-label fw-bold text-start">
                                Country:
                              </Label>{' '}
                            </div>
                            <Input
                              disabled
                              value={transaction.country}
                              type="text"
                              className="bg-white form-control text-primary input-width"
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col
                            xl="9"
                            lg="6"
                            md="5"
                            sm="7"
                            xs="10"
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="input-width">
                              <Label className="text-primary mt-2 p-label fw-bold text-start">
                                Type:
                              </Label>{' '}
                            </div>
                            <Input
                              disabled
                              value={transaction.type}
                              type="text"
                              className="bg-white form-control text-primary input-width"
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col
                            xl="9"
                            lg="6"
                            md="5"
                            sm="7"
                            xs="10"
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="input-width">
                              <Label className="text-primary mt-2 p-label fw-bold text-start">
                                Date:
                              </Label>{' '}
                            </div>
                            <Input
                              disabled
                              value={formatDate(transaction.date)}
                              type="text"
                              className="bg-white form-control text-primary input-width"
                            ></Input>
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col
                            xl="9"
                            lg="6"
                            md="5"
                            sm="7"
                            xs="10"
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="input-width">
                              <Label className="text-primary mt-2 p-label fw-bold text-start">
                                Feedback:
                              </Label>{' '}
                            </div>
                            <Input
                              disabled
                              value={transaction.result}
                              type="text"
                              className={`form-control border-0 text-white input-width ${
                                transaction.result ? 'bg-success ' : 'bg-danger '
                              }`}
                            ></Input>
                          </Col>
                        </Row>
                      </Col>
                      <div>
                        {completeTransaction?.involvedObjects && (
                          <Row className="justify-content-start justify-content-md-center mx-2 mt-4">
                            <Col
                              xs={12}
                              className="d-flex justify-content-center justify-content-xl-start"
                            >
                              <Button
                                color="accent"
                                onClick={togglePersonDetails}
                                className="mb-3"
                                style={{ width: '150px' }}
                              >
                                Person Details
                              </Button>
                            </Col>
                          </Row>
                        )}

                        {showPersonDetails && (
                          <>
                            <Row className="d-flex justify-content-center">
                              {renderInputField(
                                'Name',
                                completeTransaction.involvedObjects[0]?.name,
                              )}
                              {renderInputField(
                                'Surname',
                                completeTransaction.involvedObjects[0]?.surname,
                              )}
                              {renderInputField(
                                'Birth date',
                                completeTransaction.involvedObjects[0]?.birthday,
                              )}
                              {renderInputField(
                                'Nationality',
                                completeTransaction.involvedObjects[0]?.nationality,
                              )}
                              {renderInputField(
                                'Eye Color',
                                completeTransaction.involvedObjects[0]?.eye_color,
                              )}
                              {renderInputField(
                                'Gender',
                                completeTransaction.involvedObjects[0]?.gender,
                              )}
                              {renderInputField(
                                'Height (cm)',
                                completeTransaction.involvedObjects[0]?.height,
                              )}
                              {renderInputField(
                                'ID',
                                completeTransaction.involvedObjects[0]?.visualId,
                              )}
                              {renderInputField(
                                'Aliases',
                                completeTransaction.involvedObjects[0]?.aliases,
                                true,
                                true,
                              )}
                              {/*{renderInputField(
                                'Notes',
                                completeTransaction.involvedObjects[0].notes,
                                true,
                              )}*/}
                            </Row>
                          </>
                        )}
                      </div>
                    </Row>

                    {/* For the inputs and labels, place them in a single row and use flexbox to align them horizontally */}
                  </fieldset>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <p>Error fetching data.</p>
      )}
    </div>
  );
};

export default TransactionInfo;
