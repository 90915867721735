import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthWrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken && location.pathname !== '/auth/loginformik') {
      // Token expired , redirect to login
      sessionStorage.setItem('redirectPath', location.pathname);
      navigate('/auth/loginformik');
    }
  }, [location]);

  return children;
};

export default AuthWrapper;
